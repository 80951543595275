/* eslint react/jsx-props-no-spreading: off */
// ☝️ we want this component to be usable with chakra props
import React from "react";
import { chakra, useColorModeValue } from "@chakra-ui/react";

const HeroLogo = props => {
  const fillColor = useColorModeValue("sre.default", "sreDark.default");

  return (
    <chakra.svg
      {...props}
      role="img"
      width="800px"
      viewBox="0 0 800 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        ".hero-logo-variable path": {
          fill: fillColor,
        },
      }}
    >
      <g className="hero-logo-variable">
        <path d="M67.1829 57.1724C67.1829 56.478 66.607 55.8994 65.8007 55.8994H27.6758C26.8695 55.8994 26.2936 56.478 26.2936 57.1724C26.2936 74.8797 46.22 84.3699 69.0258 84.3699C97.821 84.3699 113.025 74.0696 113.025 57.5196C113.025 39.8124 97.821 29.2806 68.9106 29.2806C65.9159 29.2806 65.6855 27.1974 65.6855 26.3873C65.6855 24.8827 67.1829 23.6097 69.0258 23.6097C70.7535 23.6097 72.2508 24.6513 72.2508 26.2715C72.2508 27.3132 72.9419 27.8918 73.8634 27.8918H110.836C111.643 27.8918 112.219 27.3132 112.219 26.6188C112.219 9.25869 92.1772 0 69.6017 0C46.911 0 25.7177 11.5734 25.7177 27.8918C25.7177 45.7148 43.5708 54.0476 69.2561 54.1634C71.9053 54.1634 73.633 55.4364 73.633 57.5196C73.633 59.0242 72.2509 60.2973 70.5231 60.2973C68.4499 60.2973 67.1829 59.2557 67.1829 57.1724Z" />
        <path d="M120.468 0C119.661 0 119.316 0.578668 119.316 1.27307V83.3283C119.316 84.0227 119.777 84.3699 120.468 84.3699H158.247C158.938 84.3699 159.514 83.7912 159.514 83.0968V58.4455H172.184C187.848 58.4455 200.634 45.5991 200.634 28.702C200.634 12.9622 187.848 0 172.184 0H120.468ZM158.247 33.0998V25.9244H160.205C162.278 25.9244 163.891 27.5446 163.891 29.5121C163.891 31.5953 162.278 33.0998 160.205 33.0998H158.247Z" />
        <path d="M209.242 0C208.551 0 208.091 0.578668 208.091 1.15734V83.3283C208.091 83.9069 208.551 84.3699 209.242 84.3699H289.178C289.869 84.3699 290.33 84.0227 290.33 83.2125V60.1815C290.33 59.4871 289.869 59.1399 289.178 59.1399H246.791V54.9735H274.089C274.78 54.9735 275.126 54.6263 275.126 53.8162V30.6694C275.126 29.8593 274.78 29.5121 274.089 29.5121H246.791V25.3457H289.178C289.869 25.3457 290.33 24.9985 290.33 24.1883V1.15734C290.33 0.347204 289.869 0 289.178 0H209.242Z" />
        <path d="M299.003 0C298.312 0 297.851 0.578668 297.851 1.15734V83.3283C297.851 83.9069 298.312 84.3699 299.003 84.3699H378.938C379.629 84.3699 380.09 84.0227 380.09 83.2125V60.1815C380.09 59.4871 379.629 59.1399 378.938 59.1399H336.552V54.9735H363.849C364.541 54.9735 364.886 54.6263 364.886 53.8162V30.6694C364.886 29.8593 364.541 29.5121 363.849 29.5121H336.552V25.3457H378.938C379.629 25.3457 380.09 24.9985 380.09 24.1883V1.15734C380.09 0.347204 379.629 0 378.938 0H299.003Z" />
        <path d="M388.763 0C388.072 0 387.611 0.578668 387.611 1.15734V83.3283C387.611 83.9069 388.072 84.3699 388.763 84.3699H427.003C450.154 84.3699 468.929 65.3895 468.929 42.2428C468.929 18.9803 450.154 0 427.118 0H388.763ZM424.699 46.1777V37.9607H427.003C429.306 37.9607 431.149 39.8124 431.149 42.1271C431.149 44.4417 429.306 46.1777 427.003 46.1777H424.699Z" />
        <path d="M540.051 54.2791C550.187 49.8812 556.983 39.4652 556.983 28.239C556.983 11.4576 544.889 0 528.188 0H477.623C476.932 0 476.471 0.462936 476.471 1.15734V83.2125C476.471 83.9069 476.932 84.3699 477.623 84.3699H511.602C512.293 84.3699 512.753 83.9069 512.753 83.2125V74.301L519.549 83.9069C519.779 84.2541 520.125 84.3699 520.47 84.3699H558.595C559.056 84.3699 559.402 84.1384 559.632 83.7912C559.862 83.444 559.862 82.9811 559.632 82.6339L540.051 54.2791ZM513.099 31.8268V24.6513H515.057C517.13 24.6513 518.858 26.2715 518.858 28.239C518.858 30.2065 517.13 31.8268 515.057 31.8268H513.099Z" />
        <path d="M609.976 44.7889C608.363 44.7889 606.981 43.5159 606.981 41.7799V1.15734C606.981 0.462936 606.521 0 605.945 0H568.396C567.705 0 567.244 0.462936 567.244 1.15734V42.4743C567.244 65.7367 586.594 84.3699 609.976 84.3699C633.358 84.3699 652.708 65.7367 652.708 42.4743V1.15734C652.708 0.462936 652.247 0 651.556 0H614.007C613.431 0 612.971 0.462936 612.971 1.15734V41.7799C612.971 43.5159 611.588 44.7889 609.976 44.7889Z" />
        <path d="M701.575 41.8956C701.575 41.6641 701.806 41.6641 701.806 41.8956L707.104 83.3283C707.219 83.7912 707.565 84.2541 708.026 84.3699H745.805C746.381 84.3699 746.957 83.9069 746.957 83.3283V1.15734C746.957 0.462936 746.381 0 745.805 0H708.371C707.795 0 707.334 0.462936 707.334 1.15734V41.5484C707.334 41.7799 707.104 41.7799 707.104 41.5484L701.921 1.0416C701.806 0.347203 701.345 0 700.654 0H662.99C662.414 0 661.953 0.462936 661.953 1.15734V83.3283C661.953 83.9069 662.414 84.3699 662.99 84.3699H700.424C701 84.3699 701.575 83.9069 701.575 83.3283V41.8956Z" />
        <path d="M1.15181 101.512C0.460724 101.512 0 102.091 0 102.67V184.841C0 185.419 0.460724 185.882 1.15181 185.882H81.0874C81.7785 185.882 82.2392 185.535 82.2392 184.725V161.694C82.2392 161 81.7785 160.652 81.0874 160.652H38.7008V156.486H65.9987C66.6898 156.486 67.0353 156.139 67.0353 155.329V132.182C67.0353 131.372 66.6898 131.025 65.9987 131.025H38.7008V126.858H81.0874C81.7785 126.858 82.2392 126.511 82.2392 125.701V102.67C82.2392 101.86 81.7785 101.512 81.0874 101.512H1.15181Z" />
        <path d="M90.1939 101.512C89.618 101.512 89.1573 101.975 89.1573 102.554V143.177C89.1573 143.755 89.618 144.218 90.1939 144.218H111.157V184.841C111.157 185.419 111.618 185.882 112.193 185.882H149.627C150.203 185.882 150.779 185.419 150.779 184.841V144.218H171.742C172.318 144.218 172.779 143.755 172.779 143.177V102.554C172.779 101.975 172.318 101.512 171.742 101.512H90.1939Z" />
        <path d="M265.887 102.67C265.887 102.091 265.426 101.512 264.851 101.512H226.726C226.15 101.512 225.574 102.091 225.574 102.67V131.719H220.621V102.67C220.621 102.091 220.16 101.512 219.469 101.512H181.344C180.768 101.512 180.308 102.091 180.308 102.67V184.725C180.308 185.419 180.768 185.882 181.344 185.882H219.469C220.16 185.882 220.621 185.419 220.621 184.725V157.643H225.574V184.725C225.574 185.419 226.15 185.882 226.726 185.882H264.851C265.426 185.882 265.887 185.419 265.887 184.725V102.67Z" />
        <path d="M275.157 101.512C274.465 101.512 274.005 102.091 274.005 102.67V184.841C274.005 185.419 274.465 185.882 275.157 185.882H355.092C355.783 185.882 356.244 185.535 356.244 184.725V161.694C356.244 161 355.783 160.652 355.092 160.652H312.706V156.486H340.003C340.695 156.486 341.04 156.139 341.04 155.329V132.182C341.04 131.372 340.695 131.025 340.003 131.025H312.706V126.858H355.092C355.783 126.858 356.244 126.511 356.244 125.701V102.67C356.244 101.86 355.783 101.512 355.092 101.512H275.157Z" />
        <path d="M427.345 155.792C437.481 151.394 444.276 140.978 444.276 129.751C444.276 112.97 432.182 101.512 415.481 101.512H364.917C364.226 101.512 363.765 101.975 363.765 102.67V184.725C363.765 185.419 364.226 185.882 364.917 185.882H398.895C399.586 185.882 400.047 185.419 400.047 184.725V175.814L406.843 185.419C407.073 185.767 407.418 185.882 407.764 185.882H445.889C446.35 185.882 446.695 185.651 446.926 185.304C447.156 184.956 447.156 184.494 446.926 184.146L427.345 155.792ZM400.392 133.339V126.164H402.351C404.424 126.164 406.152 127.784 406.152 129.751C406.152 131.719 404.424 133.339 402.351 133.339H400.392Z" />
        <path d="M455.689 101.512C454.998 101.512 454.537 102.091 454.537 102.67V184.841C454.537 185.419 454.998 185.882 455.689 185.882H535.625C536.316 185.882 536.777 185.535 536.777 184.725V161.694C536.777 161 536.316 160.652 535.625 160.652H493.238V156.486H520.536C521.227 156.486 521.573 156.139 521.573 155.329V132.182C521.573 131.372 521.227 131.025 520.536 131.025H493.238V126.858H535.625C536.316 126.858 536.777 126.511 536.777 125.701V102.67C536.777 101.86 536.316 101.512 535.625 101.512H455.689Z" />
        <path d="M587.03 146.301C585.417 146.301 584.035 145.028 584.035 143.292V102.67C584.035 101.975 583.574 101.512 582.998 101.512H545.449C544.758 101.512 544.298 101.975 544.298 102.67V143.987C544.298 167.249 563.648 185.882 587.03 185.882C610.411 185.882 629.762 167.249 629.762 143.987V102.67C629.762 101.975 629.301 101.512 628.61 101.512H591.061C590.485 101.512 590.024 101.975 590.024 102.67V143.292C590.024 145.028 588.642 146.301 587.03 146.301Z" />
        <path d="M723.665 185.882C724.241 185.882 724.356 185.882 724.586 185.188L733.801 155.329C734.031 154.519 734.607 154.171 735.183 154.171C735.874 154.171 736.335 154.866 736.335 155.444V184.841C736.335 185.419 736.796 185.882 737.372 185.882H774.46C775.036 185.882 775.496 185.419 775.496 184.841V102.67C775.496 101.975 775.036 101.512 774.46 101.512H714.22C713.644 101.512 713.414 101.86 713.184 102.67L708.116 121.187C707.885 121.882 707.424 122.113 706.849 122.113C706.273 122.113 705.812 121.882 705.582 121.187L700.283 102.67C700.053 101.86 699.822 101.512 699.247 101.512H639.007C638.431 101.512 637.97 101.975 637.97 102.67V184.841C637.97 185.419 638.431 185.882 639.007 185.882H676.095C676.671 185.882 677.132 185.419 677.132 184.841V155.444C677.132 154.866 677.593 154.171 678.284 154.171C678.86 154.171 679.435 154.519 679.666 155.329L688.88 185.188C689.111 185.882 689.226 185.882 689.802 185.882H723.665Z" />
      </g>
      <path
        d="M766.864 171.043L763.432 168.696L761.144 169.282L761.716 218.583L764.576 220.343L768.58 217.409L772.012 212.714L775.444 210.366L778.876 215.648L780.592 220.343L781.164 223.278L784.024 226.212L788.6 227.386L790.887 224.452V220.93L788.6 214.474L785.74 210.953V208.018L796.607 206.845L797.751 203.323L794.891 199.802L791.459 196.28L788.6 193.346L785.74 190.411L782.88 187.477L778.876 183.368L774.3 178.673L770.296 174.565L766.864 171.043Z"
        fill="#C8F5FF"
      />
      <path d="M783.326 205.044V211.892H786.674V208.479H800V201.631H796.674V205.044H783.326Z" fill="#009E9E" />
      <path d="M796.674 198.195H793.326V201.63H796.674V198.195Z" fill="#009E9E" />
      <path d="M793.326 218.739H790V225.565H793.326V218.739Z" fill="#009E9E" />
      <path d="M793.326 194.783H790V198.195H793.326V194.783Z" fill="#009E9E" />
      <path d="M790 211.891H786.674V218.739H790V211.891Z" fill="#009E9E" />
      <path d="M790 191.37H786.674V194.782H790V191.37Z" fill="#009E9E" />
      <path d="M790 225.565H783.326V229H790V225.565Z" fill="#009E9E" />
      <path d="M786.674 187.935H783.326V191.37H786.674V187.935Z" fill="#009E9E" />
      <path d="M783.326 218.739H780V225.565H783.326V218.739Z" fill="#009E9E" />
      <path d="M783.326 184.522H780V187.934H783.326V184.522Z" fill="#009E9E" />
      <path d="M780 211.891H776.652V218.739H780V211.891Z" fill="#009E9E" />
      <path d="M780 181.086H776.652V184.521H780V181.086Z" fill="#009E9E" />
      <path d="M776.652 208.479H773.326V211.892H776.652V208.479Z" fill="#009E9E" />
      <path d="M776.652 177.673H773.326V181.086H776.652V177.673Z" fill="#009E9E" />
      <path d="M773.326 211.891H770V215.304H773.326V211.891Z" fill="#009E9E" />
      <path d="M773.326 174.261H770V177.674H773.326V174.261Z" fill="#009E9E" />
      <path d="M770 215.304H766.652V218.739H770V215.304Z" fill="#009E9E" />
      <path d="M770 170.826H766.652V174.261H770V170.826Z" fill="#009E9E" />
      <path d="M760 164V222.152H766.652V218.739H763.326V170.826H766.652V167.413H763.326V164H760Z" fill="#009E9E" />
    </chakra.svg>
  );
};

export default HeroLogo;
